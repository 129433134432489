import React, { useState, useEffect, Fragment } from "react";
import store from "store";
import Server from "./Server";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import ReactTable from "react-table";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import classes from "./importSFDCMetadata.module.css";
import "./connectAutomationDashboard.css";
import ConfirmDialog from "../components/ConfirmDialog";
import SITAutomationStatus from "./sitAutomationStatus";
import {
  MemberTableTitleKeys,
  MemberTableInnerKeys,
  MemberTypes,
  MembersTableColumns,
  PipelineMemberColumns,
  ErrorColumns,
  DeploymentAuditColumns,
  DeploymentAuditSubRowColumns,
} from "./ConnectAutomationDashboardConfig";

const server = new Server();

const connectEnvOptions = [
  "implement1.xactlycorp.com",
  "euimplement1.xactlycorp.com",
  "sandbox.xactlycorp.com",
  "sandbox3.xactlycorp.com",
  "sandbox4.xactlycorp.com",
  "eusb1.xactlycorp.com",
  "demo.xactlycorp.com",
  "training.xactlycorp.com",
  "other",
];
const customURLPatterns = [/implement[a-zA-Z0-9]+\.xactlycorp\.com/, /sandbox[a-zA-Z0-9]+\.xactlycorp\.com/, /euimplement[a-zA-Z0-9]+\.xactlycorp\.com/, /eusb[a-zA-Z0-9]+\.xactlycorp\.com/];
const ProcessKeys = ["Order Upload Process", "HR Upload Process"];

const ConnectAutomationDashboard = () => {
  const projectId = store.get("user")[store.get("currentUser")]["projectId"];
  const customerId = store.get("user")[store.get("currentUser")]["customerId"];
  const [connectObjects, setConnectObjects] = useState({});
  const [deploymentAudits, setDeploymentAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading Objects...");
  const [selectedTab, setSelectedTab] = useState("");
  const [loadDeploymentAudits, setLoadDeploymentAudits] = useState(false);
  const [showDeployPopup, setShowDeployPopup] = useState(false);
  const DEPLOYMENT_TAB = "Deployments";
  const SIT_AUTOMATION_TAB = "SIT Automation";
  const [showError, setShowError] = useState(false);
  const [connectProcessData, setConnectProcessData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showCustomURL, setShowCustomURL] = useState(false);
  const [modalHeight, setModalHeight] = useState(500);

  useEffect(() => {
    setIsLoading(true);
    server.getGeneratedConnectObjects(customerId, projectId, response => {
      if (response.status) {
        const connectKeys = Object.keys(response.object);
        if (connectKeys.length > 1) {
          const processData = [
            {
              name: "Select All",
              isSelected: true,
            },
          ];
          for (const key of ProcessKeys) {
            if (response.object[key] && Object.keys(response.object[key]).length) {
              Object.keys(response.object[key]).forEach(processKey => {
                processData.push({
                  name: response.object[key][processKey].name,
                  isSelected: true,
                });
              });
            }
          }
          setConnectProcessData(processData);
        }
        setIsLoading(false);
        setConnectObjects(response.object);
        setSelectedTab(Object.keys(response.object)[0]);
      }
    });
    server.getDeploymentAudits(customerId, projectId, response => {
      if (response.status) {
        setDeploymentAudits(response.object);
      }
    });
  }, []);

  useEffect(() => {
    if (loadDeploymentAudits === true) {
      setIsLoading(true);
      server.getDeploymentAudits(customerId, projectId, response => {
        if (response.status) {
          setDeploymentAudits(response.object);
        }
      });
      setLoadDeploymentAudits(false);
      setIsLoading(false);
    }
  }, [loadDeploymentAudits]);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({});

  const renderConnectionObjectDetail = connectionObject => {
    const columns = [];
    MemberTableInnerKeys.forEach(element => {
      if (connectionObject[element.accessor]) {
        columns.push(element);
      }
    });
    return (
      <div className="container mr-bt-20">
        {columns.length > 0 ? (
          <div className="container">
            <ReactTable
              filterable={false}
              showPagination={false}
              sortable={false}
              columns={[
                {
                  Header: connectionObject.name + " Details",
                  columns: columns,
                },
              ]}
              data={[connectionObject]}
              defaultPageSize={1}
              className="-striped -highlight"
            />
          </div>
        ) : null}
        <div className="container mr-bt-20">
          <RenderStepsAndPipelines members={connectionObject.members} />
        </div>
      </div>
    );
  };
  const hideDeployPopup = () => {
    setLoadDeploymentAudits(true);
    setSelectedTab(DEPLOYMENT_TAB);
    setShowDeployPopup(false);
  };
  const renderDeploymentScreen = () => {
    const [loading, setLoading] = useState(false);
    const deployContent = data => {
      if (selectedProcessCount() < 1) {
        return;
      }
      if (data.connectionInfo.env === "other") {
        if (data.connectionInfo.customURL.trim() === "") {
          setError("connectionInfo.customURL", { type: "serverError", message: "Connect Custom URL is required when 'other' is selected." });
          setModalHeight(620);
          return;
        } else {
          let customURL = data.connectionInfo.customURL.trim().toLowerCase();
          let isValid = false;
          customURLPatterns.forEach(p => (isValid = p.test(customURL) ? true : isValid));
          if (!isValid) {
            setError("connectionInfo.customURL", { type: "serverError", message: "Connect Custom URL should be one for implement or sandbox environment." });
            setModalHeight(620);
            return;
          }
        }
      }
      setLoading(true);
      data.processData = connectProcessData;
      server.deployConnectContent(customerId, projectId, data, response => {
        if (response.status === 400) {
          setError("connectionInfo.user", { type: "serverError", message: response.data.error.messages });
          setError("connectionInfo.password", { type: "serverError", message: response.data.error.messages });
          setError("connectionInfo.env", { type: "serverError", message: response.data.error.messages });
          if (data.connectionInfo.env === "other") {
            setError("connectionInfo.customURL", { type: "serverError", message: response.data.error.messages });
            setModalHeight(700);
          } else {
            setModalHeight(600);
          }
        } else if (response.status === 403) {
          alert(response.data.error.messages);
        } else {
          setLoadDeploymentAudits(true);
          setSelectedTab(DEPLOYMENT_TAB);
          setShowDeployPopup(false);
        }
        setLoading(false);
      });
    };
    const handleCheckboxChange = index => {
      const updatedItems = [...connectProcessData];
      updatedItems[index].isSelected = !updatedItems[index].isSelected;
      if (index === 0) {
        updatedItems.forEach(item => {
          item.isSelected = updatedItems[index].isSelected;
        });
      } else {
        let selectAllTrue = true;
        updatedItems.forEach((item, innerIndex) => {
          if (innerIndex !== 0 && !item.isSelected) {
            selectAllTrue = false;
          }
        });
        updatedItems[0].isSelected = selectAllTrue;
      }
      setConnectProcessData(updatedItems);
    };

    const selectedProcessCount = () => {
      const selectedProcesses = connectProcessData.filter(process => process.isSelected);
      return selectedProcesses.length;
    };
    const renderCustomURL = () => {
      return (
        <>
          <label className={classes.label} htmlFor="connectionInfo.customURL">
            Connect Custom URL
          </label>
          <input className={classes.input} {...register("connectionInfo.customURL", { required: false })} />
          {errors.connectionInfo && errors.connectionInfo.customURL && errors.connectionInfo.customURL === "required" && <p role="alert">Connect Custom URL is required.</p>}
          {errors.connectionInfo && errors.connectionInfo.customURL && errors.connectionInfo.customURL !== "required" && <p role="alert">{errors.connectionInfo.customURL.message}</p>}
        </>
      );
    };
    const handleEnvSelection = e => {
      if (e.target.value === "other") {
        setShowCustomURL(true);
        setModalHeight(590);
      } else {
        setShowCustomURL(false);
        setModalHeight(500);
      }
    };
    return (
      <Modal
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            width: "500px",
            height: modalHeight,
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
        isOpen={showDeployPopup}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={() => hideDeployPopup()}
        ariaHideApp={false}
      >
        <LoadingOverlay
          active={loading}
          spinner
          text={"Deploying connect code ..."}
          styles={{
            spinner: base => ({
              ...base,
              width: "80px",
              position: "relative",
              top: 0,
            }),

            overlay: base => ({
              ...base,
              background: "rgba(0, 0, 0, 0.2)",
            }),
          }}
        >
          <h1 className={classes.h1}>Connect Information</h1>
          <form className={classes.form} onSubmit={handleSubmit(deployContent)}>
            <>
              <label className={classes.label} htmlFor="connectionInfo.user">
                User Name
              </label>
              <input className={classes.input} {...register("connectionInfo.user", { required: true })} aria-invalid={errors.connectionInfo && errors.connectionInfo.user ? "true" : "false"} />
              {errors.connectionInfo && errors.connectionInfo.user && errors.connectionInfo.user.type === "required" && <p role="alert">User Name is required.</p>}
              {errors.connectionInfo && errors.connectionInfo.user && errors.connectionInfo.user.type !== "required" && <p role="alert">{errors.connectionInfo.user.message}</p>}
              <label className={classes.label} htmlFor="password">
                Password
              </label>
              <input
                type="password"
                className={classes.input}
                {...register("connectionInfo.password", { required: true })}
                aria-invalid={errors.connectionInfo && errors.connectionInfo.password ? "true" : "false"}
              />
              {errors.connectionInfo && errors.connectionInfo.password && errors.connectionInfo.password.type === "required" && <p role="alert">Password is required.</p>}
              {errors.connectionInfo && errors.connectionInfo.password && errors.connectionInfo.password.type !== "required" && <p role="alert">{errors.connectionInfo.password.message}</p>}
              <label className={classes.label} htmlFor="connectionInfo.env">
                Connect Environment
              </label>
              <select className={classes.input} {...register("connectionInfo.env")} onChange={handleEnvSelection}>
                {connectEnvOptions.map(value => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>
              {errors.connectionInfo && errors.connectionInfo.env && <p role="alert">{errors.connectionInfo.env.message}</p>}
              {showCustomURL && renderCustomURL()}
              {/* Displaying COnnect Process for deployment */}
              <div className="connect-perprocess">
                <p>Please select the Processes which needs to be deployed</p>
                {connectProcessData.map((processKey, index) => (
                  <div>
                    <label key={processKey.name}>
                      <input type="checkbox" checked={processKey.isSelected} onChange={() => handleCheckboxChange(index)} />
                      {processKey.name}
                    </label>
                  </div>
                ))}
                {selectedProcessCount() < 1 && <p className="error">Select atlest one process to deploy</p>}
              </div>
            </>
            <div className={classes.textCenterContainer}>
              <input className={classes.inputSubmit} type="button" onClick={e => hideDeployPopup()} value="Cancel" />
              <input className={classes.inputSubmit} type="submit" value="Deploy" />
            </div>
          </form>
        </LoadingOverlay>
      </Modal>
    );
  };
  const renderTabContent = () => {
    if (!selectedTab) {
      return null;
    }
    let selectedTabData = [];
    if (selectedTab === "Errors") {
      selectedTabData = connectObjects[selectedTab];

      return <ReactTable columns={ErrorColumns} data={selectedTabData} className="-striped -highlight" SubComponent={null} />;
    } else {
      Object.keys(connectObjects[selectedTab]).forEach(element => {
        selectedTabData.push(connectObjects[selectedTab][element]);
      });
      return (
        <div>
          <ReactTable
            filterable={false}
            showPagination={false}
            sortable={false}
            columns={MemberTableTitleKeys}
            data={selectedTabData}
            className="-striped -highlight"
            SubComponent={member => {
              return <>{renderConnectionObjectDetail(member.original)}</>;
            }}
          />
        </div>
      );
    }
  };
  const renderLoader = () => {
    return isLoading ? (
      <LoadingOverlay
        active
        spinner
        text={loadingText}
        styles={{
          wrapper: base => ({
            ...base,
            position: "fixed",
            height: "100vh",
            width: "100vw",
            top: "0",
            left: "0",
            zIndex: "1",
          }),

          spinner: base => ({
            ...base,
            height: "50px",
          }),

          overlay: base => ({
            ...base,
            background: "rgba(0, 0, 0, 0.2)",
          }),
        }}
      ></LoadingOverlay>
    ) : null;
  };
  const updateSelectedTab = tab => {
    setSelectedTab(tab);
    if (tab === DEPLOYMENT_TAB) {
      setLoadDeploymentAudits(true);
    }
  };
  const RenderNavBar = props => {
    const navTitles = props.navTitles;
    const selectedNav = props.selectedNav;
    return (
      <Navbar>
        <Nav>
          <Fragment>
            {navTitles.map(item => {
              return (
                <NavItem
                  key={item}
                  className={selectedNav === item ? "active" : ""}
                  onClick={() => {
                    props.setSelectedTab(item);
                  }}
                >
                  {item}
                </NavItem>
              );
            })}
          </Fragment>
        </Nav>
        {props.topNav && (
          <Nav pullRight>
            <NavItem key={"deploy"} onClick={() => props.setShowDeployPopup(true)}>
              Deploy
            </NavItem>
            <NavDropdown key="Export" title="Export" id="Export">
              <MenuItem key={"xsql"} onClick={getXSQL}>
                Export XSQL
              </MenuItem>
              {/* <MenuItem key="sit_cases" onClick={getSITCases}>
                Export SIT Cases
              </MenuItem> */}
            </NavDropdown>
          </Nav>
        )}
      </Navbar>
    );
  };
  const RenderMembersTable = props => {
    const [expanded, setExpanded] = useState({});
    const members = props.members;
    const columns = [
      {
        expander: true,
        width: 35,
        Expander: ({ isExpanded, ...rest }) => {
          // Expander Icon will appear only when it has subcomponent
          if (rest.original.members && rest.original.members.length > 0) {
            let className = "rt-expander";
            if (isExpanded) {
              className = className + " -open";
            }
            return <div className={className}>•</div>;
          } else {
            return null;
          }
        },
        getProps: (state, rowInfo, column) => {
          if (rowInfo) {
            if (!rowInfo.original.members || rowInfo.original.members.length === 0) {
              //  empty the onClick so it doesn't open when subcomponent is not there
              return {
                onClick: () => {},
              };
            }
          }
          return {};
        },
      },
      ...props.columns,
    ];
    return (
      <ReactTable
        filterable={true}
        defaultFilterMethod={(filter, row) => (row[filter.id] ? row[filter.id].toUpperCase().includes(filter.value.toUpperCase()) : false)}
        showPagination={true}
        sortable={true}
        columns={columns}
        defaultPageSize={5}
        data={members}
        onExpandedChange={(expanded, index, event) => {
          setExpanded(expanded);
        }}
        expanded={expanded}
        className="-striped -highlight member-table"
        SubComponent={member => {
          if (member.original.members) {
            return (
              <div className="container mr-bt-20">
                <RenderStepsAndPipelines members={member.original.members} columns={PipelineMemberColumns} />
              </div>
            );
          } else {
            return null;
          }
        }}
      />
    );
  };
  const getXSQL = () => {
    const projectId = store.get("user")[store.get("currentUser")]["projectId"];
    const customerId = store.get("user")[store.get("currentUser")]["customerId"];
    server.getConnectXSQL(customerId, projectId, response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.headers["x-suggested-filename"]);
      document.body.appendChild(link);
      link.click();
    });
  };

  const RenderStepsAndPipelines = props => {
    const stepMembers = [];
    const pipelineMembers = [];
    const navTitles = [];
    let defaultSelectedTab = "";
    props.members.forEach(member => {
      if (member.type === MemberTypes.pipeline.key) {
        pipelineMembers.push(member);
      } else {
        stepMembers.push(member);
      }
    });

    if (stepMembers.length > 0) {
      navTitles.push(MemberTypes.step.label);
      defaultSelectedTab = MemberTypes.step.label;
    }

    if (pipelineMembers.length > 0) {
      navTitles.push(MemberTypes.pipeline.label);
      if (!defaultSelectedTab) {
        defaultSelectedTab = MemberTypes.pipeline.label;
      }
    }
    const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
    return (
      <>
        {selectedTab ? (
          <>
            <RenderNavBar setSelectedTab={setSelectedTab} navTitles={navTitles} selectedNav={selectedTab} />
            <RenderMembersTable members={selectedTab === MemberTypes.step.label ? stepMembers : pipelineMembers} columns={props.columns || MembersTableColumns} />
          </>
        ) : null}
      </>
    );
  };
  return (
    <div className="mr-bt-20">
      {renderLoader()}
      <RenderNavBar
        setSelectedTab={updateSelectedTab}
        navTitles={[...Object.keys(connectObjects), DEPLOYMENT_TAB, SIT_AUTOMATION_TAB]}
        selectedNav={selectedTab}
        topNav={true}
        setShowDeployPopup={setShowDeployPopup}
      />
      <ConfirmDialog open={showError} setOpen={setShowError} alert onConfirm={() => {}}>
        {errorMessage}
      </ConfirmDialog>
      {selectedTab !== DEPLOYMENT_TAB && selectedTab !== SIT_AUTOMATION_TAB && renderTabContent()}

      {selectedTab == DEPLOYMENT_TAB && (
        <div style={{ maxWidth: "100%" }}>
          <ReactTable
            columns={DeploymentAuditColumns}
            data={deploymentAudits}
            sortable={true}
            className="-striped -highlight"
            defaultSorted={[
              {
                id: "startTime",
                desc: true,
              },
            ]}
            SubComponent={row => {
              return (
                <div style={{ maxWidth: "100%", overflowX: "auto", padding: "50px" }}>
                  <div style={{ width: "1100px" }}>
                    <ReactTable
                      columns={DeploymentAuditSubRowColumns}
                      sortable={false}
                      data={[row.original]}
                      showPagination={false}
                      defaultPageSize={1}
                      className="-striped -highlight"
                      SubComponent={null}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      )}
      {selectedTab == SIT_AUTOMATION_TAB && (
        <div style={{ maxWidth: "100%" }}>
          <SITAutomationStatus />
        </div>
      )}
      {renderDeploymentScreen()}
    </div>
  );
};

export default ConnectAutomationDashboard;
