import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { generateID, getNamesForListID, getIdFromAllObjects } from "./ManageObjectIDs";
import { validations } from "./UpdateLists";
import FilterElement from "./tableFilters";
import LoadingOverlay from "react-loading-overlay";
import Modal from "react-modal";
import DataSourceDetails from "./dataSourceDetails";
import SourceValidations from "./SourceValidations";
import { Tooltip, SecondaryButton } from "@xactlycorp/xactly-core-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose, faInfoCircle, faPlusCircle, faMinusCircle, faTrash, faTable, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../components/ConfirmDialog";
import DataTableInput_v1 from "./DataTableInput_v1.js";
import { HrMappingData } from "./HrDataMappingConfig.json";
import { standardFields } from "./CommonResources.json";
import { isObject } from "./util";

const defaultHRDataSource = {
  id: generateID("HRSources"),
  name: "HRSource",
  uploadMethod: "automated",
  connector: "ftp",
};
const mandatoryFields = [
  // User section Fields
  "Name",
  "Email",
  "Assigned Roles",

  // Person section Fields
  "Person Employee ID",
  "First Name",
  "Last Name",
  "Personal Target",
  "Personal Currency",
  "Payment Currency",
  "User",
  "Person Business Group",

  // Position section Fields
  "Position Name",
  "Title",
];

const CurrencyTypeFields = ["Personal Currency", "Payment Currency", "Salary Currency"];
class HrDataMappingTable extends DataTableInput_v1 {
  constructor(props) {
    super(props);
    this.state.showIntegrationOptions = !!(this.props.hrIntegration && this.props.tableType === "HRDataMapping02");
    this.state.columns = [
      {
        Header: "Section",
        accessor: "Section",
        maxWidth: 100,
        // aggregate: values => values.length,
        // Aggregated: row => <span>{`${row.value} Fields`}</span>,
        PivotValue: row => <span style={{ fontWeight: "bold" }}>{`${row.value} Fields`}</span>,
      },
      {
        Header: "",
        accessor: "action",
        style: { whiteSpace: "unset", overflow: "hidden" },
        maxWidth: 50,
        filterable: false,
        Aggregated: row => <span></span>,
      },
      {
        Header: "Xactly",
        accessor: "xactly",
        style: { whiteSpace: "unset", overflow: "hidden" },
        props: { type: "text" },
        Cell: this.renderEditableCell,
        maxWidth: 200,
        Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        Aggregated: row => <span></span>,
      },
      {
        Header: "DataType",
        accessor: "DataType",
        style: { whiteSpace: "unset", overflow: "hidden" },
        props: { type: "select", selectOptions: ["date", "string", "number"] },
        Cell: this.renderEditableCell,
        maxWidth: 150,
        Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
        Aggregated: row => <span></span>,
      },
    ];
    this.state.data = HrMappingData;
    this.state.expandedRows = {};
  }

  componentDidMount() {
    console.log("HrTable v1 loaded");
    this.props.saveTablularanswers(this.updateTableAnswers);
    let sourcelist = this.props.lists.Sources;
    this.NewLists = JSON.parse(JSON.stringify(this.props.lists));
    // if currData sent to the component has valid data then assign that as intial data and set state.data as the same else use default statedata.

    let initialData = this.props.currData.data === undefined ? [...this.state.data] : [...this.props.currData.data];
    //clean iniital data here
    let newData = [];
    initialData.forEach(row => {
      if (
        row.hasOwnProperty("id") &&
        this.props.lists &&
        this.props.lists.AllObjects &&
        this.props.lists.AllObjects.hasOwnProperty(row.id) &&
        this.props.lists.AllObjects[row.id].invalid &&
        this.props.lists.AllObjects[row.id].invalid === true
      ) {
      } else if (
        row.hasOwnProperty("id") &&
        row.id.endsWith("_UnitTypeName") &&
        this.props.lists &&
        this.props.lists.AllObjects &&
        this.props.lists.AllObjects.hasOwnProperty(row.id.split("_UnitTypeName")[0]) &&
        this.props.lists.AllObjects[row.id.split("_UnitTypeName")[0]].invalid &&
        this.props.lists.AllObjects[row.id.split("_UnitTypeName")[0]].invalid === true
      ) {
        // ignore this row data.
      } else {
        if (mandatoryFields.includes(row.id) && row.rowLevelProps && row.rowLevelProps.xactly) {
          row.rowLevelProps.xactly["Mandatory"] = true;
        }
        newData.push(row);
      }
    });
    initialData = newData;

    const HrSources = [];
    if (this.props.tableType === "HRDataMapping02" && HrSources.length === 0) {
      if (this.props.hrIntegration) {
        sourcelist = this.props.lists.HRSources;
      } else {
        sourcelist = [{ id: defaultHRDataSource, name: defaultHRDataSource }];
      }
    }

    sourcelist.forEach(item => {
      const sourceID = item.id || item;
      if (this.props.tableType === "HRDataMapping02") {
        HrSources.push(sourceID);
      } else {
        HrSources.push(sourceID);
      }
    });

    if (this.NewLists && this.NewLists.UnitTypes && this.NewLists.UnitTypes.length) {
      this.NewLists.UnitTypes.forEach(unitType => {
        this.AllUnitTypes.push(unitType.name);
      });
    }

    let dataWithIncentiveAttributes = this.manageIncentiveAttributes(initialData);

    const sections = [...new Set(dataWithIncentiveAttributes.map(item => item.Section))];
    const expandedRows = sections.reduce(function (acc, cur, i) {
      acc[i] = true;
      return acc;
    }, {});

    let initialColumns = [...this.state.columns];
    if (HrSources && HrSources.length === 0) {
      initialColumns.push(
        {
          Header: "",
          accessor: `Mapping_${defaultHRDataSource.id}`,
          show: true,
          props: { type: "select", selectOptions: ["Leave Blank", "Derived", "SetTo", "FieldMapping", "Other"], nullable: true },
          Cell: this.renderEditableCell,
          maxWidth: 150,
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
          Aggregated: row => <span></span>,
        },
        {
          Header: getNamesForListID(defaultHRDataSource.name, this.props.lists["HRSources"]),
          accessor: defaultHRDataSource.id,
          show: true,
          props: { type: "text", nullable: true, allowExamples: true },
          Cell: row => {
            const editableCellAttrs = {};
            if (row.original[`Mapping_${defaultHRDataSource.id}`] === "SetTo" && (row.original.id.endsWith("_UnitTypeName") || CurrencyTypeFields.includes(row.original.id))) {
              row.original.rowLevelProps[`${defaultHRDataSource.id}`] = {
                ...row.original.rowLevelProps[`${defaultHRDataSource.id}`],
                ["type"]: "select",
              };
              editableCellAttrs["selectOptions"] = JSON.parse(JSON.stringify(this.AllUnitTypes));
            }
            if (row.original[`Mapping_${defaultHRDataSource.id}`] && ["Derived", "FieldMapping"].includes(row.original[`Mapping_${defaultHRDataSource.id}`])) {
              editableCellAttrs["lists"] = JSON.parse(JSON.stringify(this.props.lists));
              editableCellAttrs["type"] = row.original[`Mapping_${defaultHRDataSource.id}`];
            }

            return <>{this.renderEditableCell(row, editableCellAttrs)}</>;
          },
          style: { whiteSpace: "unset", overflow: "hidden" },
          Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
          Aggregated: row => <span></span>,
        },
      );
      this.setState({ visibleColumns: [defaultHRDataSource.id], expandedRows }, () => this.saveTable(dataWithIncentiveAttributes, initialColumns));
    } else {
      HrSources.forEach(source => {
        initialColumns.push(
          {
            Header: "",
            accessor: `Mapping_${source}`,
            show: true,
            props: { type: "select", selectOptions: ["Leave Blank", "Derived", "SetTo", "FieldMapping", "Other"], nullable: true },
            Cell: this.renderEditableCell,
            maxWidth: 150,
            style: { whiteSpace: "unset", overflow: "hidden" },
            Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            Aggregated: row => <span></span>,
          },
          {
            Header: getNamesForListID(source, this.props.lists["HRSources"]),
            accessor: source,
            show: true,
            props: { type: "text", nullable: true, allowExamples: true },
            Cell: row => {
              const editableCellAttrs = {};
              if (row.original[`Mapping_${source}`] === "SetTo" && (row.original.id.endsWith("_UnitTypeName") || CurrencyTypeFields.includes(row.original.id))) {
                row.original.rowLevelProps[`${source}`] = {
                  ...row.original.rowLevelProps[`${source}`],
                  ["type"]: "select",
                };
                editableCellAttrs["selectOptions"] = JSON.parse(JSON.stringify(this.AllUnitTypes));
              }
              if (row.original[`Mapping_${source}`] && ["Derived", "FieldMapping"].includes(row.original[`Mapping_${source}`])) {
                editableCellAttrs["lists"] = JSON.parse(JSON.stringify(this.props.lists));
                editableCellAttrs["type"] = row.original[`Mapping_${source}`];
              }

              return <>{this.renderEditableCell(row, editableCellAttrs)}</>;
            },
            style: { whiteSpace: "unset", overflow: "hidden" },
            Filter: ({ filter, onChange }) => FilterElement(filter, onChange),
            Aggregated: row => <span></span>,
          },
        );
      });
      this.setState({ visibleColumns: HrSources, expandedRows }, () => this.saveTable(dataWithIncentiveAttributes, initialColumns));
    }
  }

  updateRowLevelProps = data => {
    let newData = [];
    data.forEach(row => {
      if (
        row.hasOwnProperty("id") &&
        this.props.lists &&
        this.props.lists.hasOwnProperty("AllObjects") &&
        this.props.lists.AllObjects.hasOwnProperty(row.id) &&
        this.props.lists.AllObjects[row.id].invalid &&
        this.props.lists.AllObjects[row.id].invalid === true
      ) {
      } else {
        this.state.visibleColumns.forEach(source => {
          if (row[`Mapping_${source}`] && ["Derived", "FieldMapping"].includes(row[`Mapping_${source}`])) {
            if (!row.rowLevelProps) {
              row.rowLevelProps = {};
            }
            if (row.rowLevelProps && !row.rowLevelProps[source]) {
              row.rowLevelProps[source] = { type: "expression", options: { expressionType: row[`Mapping_${source}`] === "Derived" ? "evaluation" : "mapping", sourceType: this.props.tableType } };
            } else {
              row.rowLevelProps[source].type = "expression";
              row.rowLevelProps[source].options = { expressionType: row[`Mapping_${source}`] === "Derived" ? "evaluation" : "mapping", sourceType: this.props.tableType };
            }
          }
        });
        if (row.deletable) {
          row["action"] = <FontAwesomeIcon icon={faTrash} id={row.id} onClick={() => this.handleRemoveTableItems(row)} className="Clickable"></FontAwesomeIcon>;
        } else if (row.infoNote) {
          row["action"] = (
            <>
              <FontAwesomeIcon id={row.id} icon={faInfoCircle} style={{ color: "#eea236" }} data-for={`info-${row.id}`} data-tip="react-tooltip" />
              <Tooltip id={`info-${row.id}`} placement="top">
                {row.infoNote || ""}
              </Tooltip>
            </>
          );
        }
        if (mandatoryFields.includes(row.id)) {
          row.isRequired = true;
        }
        newData.push(row);
      }
    });
    return newData;
  };

  manageIncentiveAttributes = initialData => {
    let initialFields = [];
    for (let f = 0; f < initialData.length; f++) {
      initialFields.push(initialData[f]["id"] || initialData[f]["xactly"]);
    }
    // push splitAmount into this array as well so that it does not show up as a custom field
    const standardFieldIds = this.NewLists.AllAttributes.filter(item => item.hasOwnProperty("visibleInFormulas") && item.type === "People").map(item => item.id);
    initialFields.push(...standardFieldIds);
    this.props.lists.QualifierAttributes.forEach(item => {
      if (this.props.tableType === "HRDataMapping02" && item.type === "People") {
        if (!(initialFields.includes(item.id) || initialFields.includes(item.name) || item.name === "All")) {
          initialData.push({
            id: item.id,
            xactly: item.name,
            rowLevelProps: { xactly: { Disabled: false }, DataType: { Disabled: false } },
            DataType: item.dataType || "string",
            IncentFieldType: item.type,
            Section: "Person",
          });
        }
      }
    });
    for (var row = 0; row < initialData.length; row++) {
      if (!standardFields[this.props.tableType].includes(initialData[row]["xactly"])) {
        const attrDet = this.props.lists.AllAttributes.filter(r => r.id === initialData[row].id)[0];
        if (attrDet) {
          initialData[row]["xactly"] = attrDet.name;
          initialData[row]["DataType"] = attrDet.dataType;
          initialData[row].IncentFieldType = attrDet.type;
        }
        if (initialData[row].IncentFieldType !== "People") {
          initialData.splice(row, 1);
        }
      }
    }
    return initialData;
  };

  validateData = (data, visibleColumns) => {
    let names = {},
      customFields = [];

    let d = JSON.parse(JSON.stringify(data));
    let charLength_MIN = validations.fieldLengthBounds.QualifierAttributes.min;
    let charLength_MAX = validations.fieldLengthBounds.QualifierAttributes.max;
    let charLength_MAX_NUM = validations.fieldLengthBounds.QualifierAttributes.maxNum;

    for (let row = 0; row < d.length; row++) {
      //validate only the custom fields
      if (!standardFields[this.props.tableType].includes(d[row]["xactly"])) {
        if (!d[row]["id"].includes("_UnitTypeName")) {
          d[row]["deletable"] = true;
        }
        //check for numeric fields
        if (d[row].DataType === "number") {
          let unitTypeRow = d.filter(r => r.id === `${d[row].id}_UnitTypeName`)[0];
          if (!unitTypeRow) {
            d.splice(row + 1, 0, {
              id: `${d[row].id}_UnitTypeName`,
              xactly: `${d[row].xactly}_UnitTypeName`,
              DataType: "string",
              IncentFieldType: "People",
              rowLevelProps: { xactly: { Disabled: true }, DataType: { Disabled: true } },
              Section: "Person",
            });
            ++row;
          } else {
            unitTypeRow.xactly = `${d[row].xactly}_UnitTypeName`;
          }
        } else if (d.filter(r => r.id === `${d[row].id}_UnitTypeName`)[0]) {
          d.splice(row + 1, 1); // remove unitType row if data type is not number
          --row;
        }
      }
      if (visibleColumns && visibleColumns.length > 0) {
        visibleColumns.forEach(src => {
          if (["SetTo", "Other"].includes(d[row][`Mapping_${src}`]) && d[row][`${src}`] && d[row][`${src}`].hasOwnProperty("expWithName")) {
            d[row][`${src}`] = d[row][`${src}`]["expWithName"];
          }
        });
      }
    }

    for (let row = 0; row < d.length; row++) {
      //validate only the custom fields
      if (!standardFields[this.props.tableType].includes(d[row]["xactly"])) {
        customFields.push({ name: d[row]["xactly"], id: d[row]["id"], dataType: d[row].DataType });

        if (d[row]["xactly"].match(/^[A-Za-z][A-Za-z0-9_]*$/g) === null) {
          this.errorStatus(true, { row: row + 1, column: "Xactly" }, "No special characters allowed");
        } else {
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, "No special characters allowed");
        }
        // Check Character length for all custom field.
        if (d[row].DataType === "number") {
          if (d[row].xactly.length < charLength_MIN || d[row].xactly.length > charLength_MAX_NUM) {
            this.errorStatus(true, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
          } else {
            this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
          }
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
        } else {
          if (d[row].xactly.length < charLength_MIN || d[row].xactly.length > charLength_MAX) {
            this.errorStatus(true, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
          } else {
            this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX}.`);
          }
          this.errorStatus(false, { row: row + 1, column: "Xactly" }, `Characters length should be between ${charLength_MIN} and ${charLength_MAX_NUM}.`);
        }
      }

      names[d[row]["xactly"]] = names[d[row]["xactly"]] || [];
      if (!d[row].excludeDuplicateCheck) {
        names[d[row]["xactly"]].push(d[row]["xactly"]);
      }
    }
    //check for duplicate names
    for (let name in names) {
      if (names[name].length > 1) {
        this.errorStatus(true, { row: "Leave Blank", column: "Xactly" }, `Duplicate Name : ${name}`);
      } else {
        this.errorStatus(false, { row: "Leave Blank", column: "Xactly" }, `Duplicate Name : ${name}`);
      }
    }

    if (Object.keys(this.errors).length === 0) {
      ["QualifierAttributes", "ComputationAttributes"].forEach(list => {
        this.NewLists[list].forEach(row => {
          customFields.forEach(field => {
            if (row.id === field.id) {
              row.name = field.name;
              row.dataType = field.dataType;
              row.type = this.props.tableType === "DataMapping" ? "OrderItem" : "People";
            }
          });
        });
      });
    }

    return d;
  };

  handleRemoveTableItems = row => {
    if (!standardFields[this.props.tableType].includes(row["xactly"])) {
      if (this.state.isTableDataModified) {
        this.setState({ saveTableWarningModal: { saveTableWarning: true, onConfirm: () => this.handleRemoveCustomFields(row.id) } });
      } else {
        this.handleRemoveCustomFields(row.id);
      }
    } else {
      console.info("Can not delete standard Fields");
    }
  };

  addRow = () => {
    const newData = [...this.state.data];
    let key = generateID("QualifierAttributes");
    let defaultName = "New_Custom_Field";
    const IncentFieldType = this.props.tableType === "HRDataMapping02" ? "People" : null,
      dataType = "string";
    newData[this.state.data.length] = {
      id: key,
      xactly: defaultName,
      DataType: dataType,
      IncentFieldType: IncentFieldType,
    };

    const listItem = { name: defaultName, id: key, type: IncentFieldType, dataType: dataType };
    this.NewLists.QualifierAttributes.push(listItem);
    this.saveTable(newData);
    this.saveAttributeLists(newData);
  };

  displayColumns = () => {
    let col = this.state.columns.map(c => {
      let column = { ...c };
      let systemColumns = ["Section", "action", "xactly", "DataType"];

      if (!systemColumns.includes(column.accessor) && !column.accessor.startsWith("Mapping_")) {
        column.Header = () => {
          return (
            <div style={{ display: "inline-block" }}>
              {this.props.lists["HRSources"].length > 0
                ? getNamesForListID(column.accessor, this.props.lists["HRSources"])
                : getNamesForListID(defaultHRDataSource.name, this.props.lists["HRSources"])}
              {this.state.showIntegrationOptions && (
                <div>
                  <FontAwesomeIcon
                    style={{ margin: "0px 0px 0px 5px" }}
                    icon={faInfoCircle}
                    className="Clickable LeftAlign "
                    onClick={() => {
                      if (this.state.isTableDataModified) {
                        this.setState({
                          saveTableWarningModal: {
                            saveTableWarning: true,
                            onConfirm: () => {
                              this.setState({
                                showIntegrationModule: true,
                                selectedSource: column.accessor,
                              });
                            },
                          },
                        });
                      } else {
                        this.setState({
                          showIntegrationModule: true,
                          selectedSource: column.accessor,
                        });
                      }
                    }}
                  />
                  {this.props.tableType === "DataMapping" && (
                    <FontAwesomeIcon
                      style={{ margin: "0px 0px 0px 5px" }}
                      icon={faTable}
                      className="Clickable LeftAlign "
                      onClick={() => {
                        this.setState({
                          selectedSource: column.accessor,
                          showValidationModule: true,
                        });
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        };
      }

      if (column.props && column.props.lists) {
        column.props.lists = this.props.lists;
      }
      return column;
    });
    return col;
  };

  onExpandedChange = expandedRows => {
    this.setState({ expandedRows });
  };

  render() {
    let newData = this.displayData();
    const newLists = JSON.parse(JSON.stringify(this.props.lists));
    if (newLists["HRSources"].length === 0) {
      newLists["HRSources"] = [defaultHRDataSource];
    }

    return (
      <div>
        {this.state.showIntegrationOptions && (
          <div className="SubSection Clickable" onClick={this.toggleHelpText}>
            {
              <label className="Clickable">
                {!this.state.helpTextVisible && <FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon>}
                {this.state.helpTextVisible && <FontAwesomeIcon icon={faMinusCircle}></FontAwesomeIcon>}
                <span> Mapping Instructions</span>
              </label>
            }
            {this.state.helpTextVisible && (
              <ol className="HelpSection">
                <li key={5}>Add new data sources using the drop down menu below.</li>
                <li key={0}>Click "Manage Reference Sources" button on the right to define any required reference sources.</li>
                <li key={1}>
                  {
                    <span>
                      Click on <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon> icon next to column heading to add source details and define relationships to other reference sources.
                    </span>
                  }
                </li>
                <li key={2}> For each Xactly field, select one of the four mapping types: "SetTo", "Field Mapping", "Derivation", or "Other".</li>
                <li key={3}>
                  {" "}
                  For "Field Mapping" or "Derivation", an expression box will be displayed. In order to build an expression, use "@s" to select from defined sources and @t (only for 'Derived') to
                  select a transformation.
                </li>
                <li key={4}> If multiple relationships are defined for a source, then select the relationship that is applicable for the derivation.</li>
                <li key={6}>{'For fields marked as "SetTo" or "Other", use < > notation for defining parameters. E.g. Booking_<periodName>_<periodStartDate>_<periodEndDate> as "Batch Name".'} </li>
              </ol>
            )}
          </div>
        )}
        <div className="flexContainer">
          {this.state.showIntegrationOptions && (
            <div className="flexContainer" style={{ "justify-content": "right" }}>
              <div style={{ padding: "10px" }}>
                <button
                  className="btn btn-success flexItem"
                  type="button"
                  onClick={() => {
                    if (this.state.isTableDataModified) {
                      this.setState({
                        saveTableWarningModal: {
                          saveTableWarning: true,
                          onConfirm: () => {
                            this.setState({
                              showIntegrationModule: true,
                              showReferenceSources: true,
                              showValidationModule: false,
                              selectedSource: "",
                            });
                          },
                        },
                      });
                    } else {
                      this.setState({
                        showIntegrationModule: true,
                        showReferenceSources: true,
                        showValidationModule: false,
                        selectedSource: "",
                      });
                    }
                  }}
                >
                  {"Manage Reference Sources"}
                </button>
              </div>
            </div>
          )}
          <br />
        </div>
        <div className="Tabular">
          {Object.values(this.errors).length > 0 ? (
            <div className="alert alert-danger" role="alert">
              {this.displayErrorMessages()}
            </div>
          ) : (
            ""
          )}
          <LoadingOverlay
            active={!this.state.dataReady}
            spinner
            text="loading ..."
            styles={{
              spinner: base => ({
                ...base,
                width: "80px",
              }),

              overlay: base => ({
                ...base,
                background: "rgba(0, 0, 0, 0.2)",
              }),
            }}
          >
            <ReactTable
              sortable={false}
              columns={this.displayColumns()}
              data={newData}
              className="-striped -highlight"
              pageSize={Object.keys(this.state.expandedRows).length}
              filterable
              defaultFilterMethod={(filter, row) => {
                if (row._subRows === undefined) {
                  let searchResult = false;
                  if (row[filter.id]) {
                    if (isObject(row[filter.id]) && row[filter.id].hasOwnProperty("expWithName")) {
                      searchResult = row[filter.id]["expWithName"] ? row[filter.id]["expWithName"].toUpperCase().includes(filter.value.toUpperCase()) : false;
                    } else {
                      searchResult = row[filter.id].toUpperCase().includes(filter.value.toUpperCase());
                    }
                  }
                  return searchResult;
                } else {
                  return true;
                }
              }}
              showPagination={false}
              pivotBy={["Section"]}
              expanded={this.state.expandedRows}
              onExpandedChange={this.onExpandedChange}
            />
          </LoadingOverlay>
        </div>
        <button
          className="btn btn-success btn-padded"
          onClick={this.state.isTableDataModified ? () => this.setState({ saveTableWarningModal: { saveTableWarning: true, onConfirm: this.addRow } }) : this.addRow}
        >
          Add New Field
        </button>
        <ConfirmDialog
          open={this.state.saveTableWarningModal.saveTableWarning}
          setOpen={val => {
            this.setState({ saveTableWarningModal: { saveTableWarning: val, onConfirm: () => {} } });
          }}
          onConfirm={this.state.saveTableWarningModal.onConfirm}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FontAwesomeIcon icon={faTriangleExclamation} color="#f0ad4e" size="2xl" />
            <div style={{ "padding-left": "10px" }}>
              <b>STOP!</b> Make sure that all changes to data mapping table is saved before proceeding further. Failed to do so may result in loss of unsaved mapping.
            </div>
          </div>
        </ConfirmDialog>
        {
          <Modal
            isOpen={this.state.showIntegrationModule}
            contentLabel="Data Source Details"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={true}
            onRequestClose={() => {
              this.setState({
                showIntegrationModule: false,
                selectedSource: "",
              });
            }}
            style={{
              content: {
                top: "40px",
                left: "40px",
                height: "70%",
              },
            }}
          >
            <div>
              <SecondaryButton
                icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                clickHandler={() => {
                  this.setState({
                    showIntegrationModule: false,
                    showReferenceSources: false,
                  });
                }}
                iconSize={20}
              />
              <Tooltip id="closeModal">Close</Tooltip>
            </div>
            <DataSourceDetails
              lists={newLists}
              selectedSource={this.state.selectedSource}
              sourceType={this.props.tableType}
              customerId={this.props.customerId}
              projectId={this.props.projectId}
              closeDataSourceDetailsModule={this.closeDataSourceDetailsModule}
              saveProjectDetails={updatedData => {
                this.NewLists = updatedData.AllLists;
                this.props.saveProjectDetails(updatedData);
              }}
              showReferenceSources={this.state.showReferenceSources}
              SendQuestionPath={this.props.SendQuestionPath}
              isCustomHrSource={true}
            />
          </Modal>
        }
        {
          <Modal
            style={{ content: { height: "60%", width: "75%", margin: "auto" } }}
            isOpen={this.state.removeTableRowModule.isRemoving}
            onRequestClose={() => {
              this.setState({
                removeTableRowModule: {
                  rowId: "",
                  isRemoving: false,
                  errors: [],
                  warnings: [],
                },
              });
            }}
          >
            <div style={{ padding: "20px" }}>
              <div>
                <SecondaryButton
                  icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                  clickHandler={() => {
                    this.setState({
                      removeTableRowModule: {
                        rowId: "",
                        isRemoving: false,
                        errors: [],
                        warnings: [],
                      },
                    });
                  }}
                  iconSize={20}
                />
                <Tooltip id="closeModal">Close</Tooltip>
              </div>
              {this.state.removeTableRowModule.errors.length > 0 && (
                <div>
                  <h4 style={{ color: "red" }}>{"Errors: "}</h4>
                  <div>{`Please address the following errors before deleting ${getIdFromAllObjects(this.state.removeTableRowModule.rowId, this.NewLists.AllObjects)}:`}</div>
                  <span />
                  <ul>
                    {this.state.removeTableRowModule.errors.map(error => (
                      <li>
                        <a className="Clickable" onClick={e => this.handleSelect(error.link)}>
                          {error.message}
                        </a>
                      </li>
                    ))}
                  </ul>
                  <hr />
                </div>
              )}
              {this.state.removeTableRowModule.warnings.length > 0 && (
                <div>
                  <h4>{"Warnings: "}</h4>
                  <div>{`Note: Deleting this object will lose any related captured requirements.`}</div>
                  <span />
                  <ul>
                    {this.state.removeTableRowModule.warnings.map(warning => (
                      <li>
                        {warning.link !== null ? (
                          <a className="Clickable" onClick={e => this.handleSelect(warning.link)}>
                            {warning.message}
                          </a>
                        ) : (
                          <span>{warning.message}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                  <hr />
                </div>
              )}
              {this.state.removeTableRowModule.allowDelete ? (
                <button
                  style={{ left: "50%", right: "50%" }}
                  onClick={e => {
                    this.deleteRow(this.state.removeTableRowModule);
                  }}
                >
                  Confirm Delete
                </button>
              ) : (
                <button style={{ left: "50%", right: "50%", color: "red" }} disabled={!this.state.removeTableRowModule.allowDelete}>
                  Cannot Delete
                </button>
              )}
            </div>
          </Modal>
        }
        {
          <Modal
            style={{ content: { height: "75%", width: "75%", margin: "auto" } }}
            isOpen={this.state.showValidationModule}
            onRequestClose={() => {
              this.setState({
                showValidationModule: false,
              });
            }}
          >
            <div>
              <SecondaryButton
                icon={<FontAwesomeIcon icon={faWindowClose} data-for="closeModal" data-tip="react-tooltip" />}
                clickHandler={() => {
                  this.setState({
                    showValidationModule: false,
                  });
                }}
                iconSize={20}
              />
              <Tooltip id="closeModal">Close</Tooltip>
              <SourceValidations AllLists={this.props.lists} selectedSource={this.props.lists.Sources.filter(item => item.id === this.state.selectedSource)[0]} sourceType={"DataMapping"} />
            </div>
          </Modal>
        }
      </div>
    );
  }
}

export default HrDataMappingTable;
