import Server from "./Server";
import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import UpdateLists from "./UpdateLists";
import Modal from "react-modal";
import Websocket from "react-websocket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { listHeaders } from "./util";
import { WS_ROOT } from "./api-config";

const server = new Server();

//var PreProjectChecklists = ["Titles", "Plans", "Incentives", "Sources", "Quotas", "QualifierAttributes"];
const alertBoxStyle = { border: "1px solid #D47800", borderRadius: "2px", textAlign: "left" };
const LOCKED_TITLE_MAP = {
  Organization: ["Titles", "Plans", "Incentives", "Sources", "Quotas", "QualifierAttributes", "UnitTypes"],
  Data: ["Sources", "HRSources", "QualifierAttributes"],
};

class ProjectLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
      selectedList: "",
      sectionLockStatus: [],
    };
    this.listWithIDs = true;
    this.AllLists = [];
    this.projectDetails = {};
    this.hrIntegration = false;
    this.allowUpdates = this.props.allowUpdates;
    this.PreProjectChecklists = ["Titles", "Plans", "Incentives", "Sources", "Quotas", "QualifierAttributes", "UnitTypes"];
  }

  componentDidMount() {
    this.renderProjectData();
  }

  renderProjectData = () => {
    server.getProjectDetails(this.props.customerId, this.props.projectId, response => {
      this.projectDetails = response.data.object[0];
      this.hrIntegration = response.data.object[0].GlobalVariables ? (response.data.object[0].GlobalVariables.hrIntegration ? response.data.object[0].GlobalVariables.hrIntegration : false) : false;
      if (this.hrIntegration) {
        this.PreProjectChecklists = ["Titles", "Plans", "Incentives", "Sources", "Quotas", "QualifierAttributes", "UnitTypes"];
      }
      this.props.saveProjectDetails(this.projectDetails);
      this.AllLists = response.data.object[0].AllLists;
      //For backward compatibility.

      if (this.AllLists.AllObjects === undefined) {
        this.AllLists.AllObjects = {};
      }

      this.setState(this.createTableData());
    });
  };

  saveProjectDetails = projectData => {
    this.props.saveProjectDetails(projectData);
    this.AllLists = projectData.AllLists;
    this.setState(this.createTableData());
  };

  handleListSelection = formData => {
    if (formData.length > 0) {
      this.listWithIDs = typeof this.AllLists[formData[0].id][0] === "string" ? false : true; // for backward compatibility
      this.setState({ selectedList: formData[0] }, () => {});
    } else this.setState({ selectedList: "" });
  };

  createTableData = () => {
    let len = 0;
    let longestlist = "";
    let columns = [];
    let data = [];

    for (let list in this.AllLists) {
      if (this.PreProjectChecklists.includes(list)) {
        columns.push({
          Header: (
            <div>
              {listHeaders[list]}{" "}
              <button
                type="button"
                onClick={() => {
                  this.handleListSelection([{ id: list, name: listHeaders[list] }]);
                }}
                className="btn "
              >
                <FontAwesomeIcon icon={faPlusCircle} />
              </button>
            </div>
          ),
          accessor: list,
        });
        let currlistlength = this.AllLists[list].length;
        if (currlistlength >= len) {
          len = currlistlength;
          longestlist = list;
        }
      }
    }

    for (var i = 0; i < this.AllLists[longestlist].length; i++) {
      data[i] = {};
      for (let list in this.AllLists) {
        if (this.AllLists[list][i] !== undefined) {
          this.listWithIDs = this.AllLists[list][i].name ? true : false;
          data[i][list] = this.AllLists[list][i].name || this.AllLists[list][i]; // Or condition required for backward compatibility
        }
      }
    }

    return { data: data, columns: columns, selectedList: "" };
  };

  render() {
    let ListComponent = this.props.dynamicComponentMapping.UpdateLists || UpdateLists;
    console.log("List component that is available");
    console.log(ListComponent);
    console.log(this.props.dynamicComponentMapping.UpdateLists);
    console.log("List component that is available");
    // Assign TRUE if any of sections('Organization' or 'Data') is locked.
    let isSelectedTitleLocked = this.state.sectionLockStatus.some(lockStaus => lockStaus.isLocked === true);

    // Logic to decide if selected title should be locked or not based on 'Organization' or 'Data' section locks.
    if (isSelectedTitleLocked) {
      const LockedColumns = [];
      this.state.sectionLockStatus.forEach(lockObject => {
        LockedColumns.push(...LOCKED_TITLE_MAP[lockObject.section]);
      });
      isSelectedTitleLocked = [...new Set(LockedColumns)].includes(this.state.selectedList.id);
    }

    return (
      <div>
        <div className="Tabular">
          {this.state.columns.length >= 0 ? (
            <>
              <Websocket
                url={`${WS_ROOT}/customers/${this.props.customerId}/projects/${this.props.projectId}/section/SHOW_LIST/sectionLock`}
                onMessage={data => {
                  const result = JSON.parse(data);
                  if (result.action === "LOCKED") {
                    const lockArr = [...this.state.sectionLockStatus];
                    lockArr.push({
                      isLocked: true,
                      section: result.section,
                      lockedBy: result.user,
                      message: `You cannot edit or delete anything in ShowList as there are changes being done by ${result.user}. But you can add more options to these lists.`,
                    });
                    this.setState({
                      sectionLockStatus: lockArr,
                    });
                  } else if (result.action === "REMOVE_LOCK") {
                    const filteredArr = this.state.sectionLockStatus.filter(lockItem => lockItem.section !== result.section && lockItem.lockedBy !== result.user);
                    this.setState({
                      sectionLockStatus: filteredArr,
                    });
                  } else if (result.action === "IS_ALIVE") {
                    // Notify server If connection is alive.
                    if (this.refWebSocket) {
                      this.refWebSocket.sendMessage(
                        JSON.stringify({
                          action: "IS_ALIVE",
                        }),
                      );
                    }
                  } else {
                    this.setState({
                      sectionLockStatus: [],
                    });
                  }
                }}
                onOpen={() => {
                  console.log("SHOW_LIST Connection OPENED");
                }}
                onClose={() => {
                  console.log("SHOW_LIST Connection CLOSED");
                }}
                debug={true}
                ref={Websocket => {
                  this.refWebSocket = Websocket;
                }}
              />
              {this.state.sectionLockStatus.some(lockStaus => lockStaus.isLocked === true) && (
                <Alert bsStyle="warning" style={alertBoxStyle}>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> {this.state.sectionLockStatus[0].message}
                </Alert>
              )}
              <ReactTable showPagination={true} sortable={false} columns={this.state.columns} data={this.state.data} className="-striped -highlight" />
            </>
          ) : (
            ""
          )}
        </div>

        <Modal
          isOpen={this.state.selectedList !== ""}
          contentLabel="Component Summary"
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={() => {
            this.handleListSelection([]);
          }}
          style={{
            content: {
              top: "40px",
              left: "40px",
              height: "70%",
            },
          }}
        >
          <ListComponent
            AllLists={this.AllLists}
            selectedList={this.state.selectedList.id}
            listWithIDs={this.listWithIDs}
            customerId={this.props.customerId}
            projectId={this.props.projectId}
            projectDetails={this.projectDetails}
            saveProjectDetails={this.saveProjectDetails.bind(this)}
            SendQuestionPath={qid => {
              this.props.SendQuestionPath(qid);
            }}
            closeModal={() => this.handleListSelection([])}
            isLocked={isSelectedTitleLocked}
          />
        </Modal>
      </div>
    );
  }
}

export default ProjectLists;
