import { ComponentMap } from "./component-map";
import UpdateLists from "./UpdateLists";
import UpdateLists_v2 from "./updateLists_v2";
import UpdateLists_v3 from "./updateLists_v3";
import UpdateLists_v4 from "./updateLists_v4";
import UpdateLists_v5 from "./updateLists_v5";

import DataTableBuilder from "./DataTableBuilder";
import DataTableInput from "./DataTableInput";
import DataTableInput_v1 from "./DataTableInput_v1";

import HrDataMappingTable from "./HrDataMappingTable";

import RateTableInput from "./RateTableBuilder";
import RateTable from "./rateTableBuilder_v1";

import TitlesTableBuilder from "./TitlesTableBuilder";

let componentMapping = {};

const initializeModule = async Comp => {
  for (let componentKey in Comp) {
    if (componentKey !== "UpdateLists") {
      componentMapping[componentKey] = {};
      for (let version in Comp[componentKey]) {
        let module = await import(`./${Comp[componentKey][version]}`);
        componentMapping[componentKey][version] = module.default;
      }
    }
  }
  return componentMapping;
};

const initializeDataTable = () => {
  componentMapping["DataMappingTable"] = {
    default: DataTableBuilder,
    v2: DataTableInput,
    v3: DataTableInput_v1,
  };
};

const initializeHRDataTable = () => {
  componentMapping["HrDataMappingTable"] = {
    default: HrDataMappingTable,
    v1: HrDataMappingTable,
  };
};

const initializeRateTableBuilder = () => {
  componentMapping["RateTableBuilder"] = {
    default: RateTableInput,
    v1: RateTable,
  };
};

const initializeUpdateLists = () => {
  componentMapping["UpdateLists"] = {
    default: UpdateLists,
    v2: UpdateLists_v2,
    v3: UpdateLists_v3,
    v4: UpdateLists_v4,
    v5: UpdateLists_v5,
  };
};

const initializeTitlesTableBuilder = () => {
  componentMapping["TitlesTableBuilder"] = {
    deafult: TitlesTableBuilder,
  };
};

initializeUpdateLists();
initializeDataTable();
initializeHRDataTable();
initializeRateTableBuilder();

// initializeModule(ComponentMap);

/**
 * ### Overview
 * This component to dynamically render different versions of a component based on a component mapping file
 */
class ComponentProvider {
  //  static componentMapping = initializeModule();
  static getComponent = async (componentKey, version) => {
    if (!componentMapping[componentKey]) {
      console.log("component key not found");
    } else {
      let elem = componentMapping[componentKey]["default"];
      if (version in componentMapping[componentKey]) {
        elem = componentMapping[componentKey][version];
      }
      return elem;
    }
  };
}

export default ComponentProvider;
